<template>
  <div class="he-zuo">
    <!--    <fen-ge-fu>合作伙伴</fen-ge-fu>-->
    <div class="da-hang">
      <van-tabs v-model="active" @click="clickZhuanJiaKu">
        <van-tab title="合作伙伴" name="heZuoHuoBan"></van-tab>
        <van-tab title="专家库" name="PlatformSpecialistLibrary"></van-tab>
      </van-tabs>
    </div>
    <div class="lun-bo">
      <div class="swiper-container" ref="lunBo">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="vo in list">
            <div class="he-zuo-list">
              <a :href="vo.href" target="_blank">
                <div class="he-zuo-list-image">
                  <img class="he-zuo-list-image-el" :src="vo.image[0].url" alt="">
                </div>
                <div class="he-zuo-list-name">
                  {{ vo.name }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FenGeFu from "../../open/FenGeFu";
import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'
import http from "../../../api/http";
import XEUtils from "xe-utils";
import $ from 'jquery'

export default {
  name: 'HomeHeZuoHuoBan',
  components: {FenGeFu},
  mixins: [],
  data() {
    return {
      active: 0,
      list: [],
      swiperconfig: {
        loop: false, //环路，让Swiper看起来是循环的
        autoplay: {
          delay: 2000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false //用户操作swiper之后，是否禁止autoplay
        },
        paginationClickable: true,
        spaceBetween: 12,
        slidesPerView: XEUtils.toInteger($('body').width() / 100),
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    clickZhuanJiaKu(page) {
      this.$router.push({name: page})
    },
    getData() {
      http.get('/gzhphp/guang_gao/getHeZuoHuoBan').then(da => {
        if (da.code === 1) {
          this.list = da.data
          this.$nextTick(() => {
            const mySwiper = new Swiper(this.$refs.lunBo, this.swiperconfig)
            mySwiper.el.onmouseover = function () { //鼠标放上暂停轮播
              mySwiper.autoplay.stop();
            }
            mySwiper.el.onmouseleave = function () {
              mySwiper.autoplay.start();
            }
          })
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.he-zuo {
  margin: 10px;
  background-color: @white;
  padding: 5px 10px 20px;
  border-radius: 10px;
  box-shadow: @yin-ying-all;
}

.lun-bo {
  margin-top: 13px;
}

.he-zuo-list-image {
  text-align: center;

  .he-zuo-list-image-el {
    width: 100%;
    max-width: 100px;
  }

}

.he-zuo-list-name {
  text-align: center;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
  margin-top: 9px;
  color: @black;
}

.miao-shu {
  width: 100%;
  min-height: 100px;
  max-height: 300px;
}

.da-hang {
  ::v-deep .van-tab__text {
    font-size: 15px;
  }
}
</style>
