<template>
  <div class="dong-tai">
    <van-row class="lian-xi-ke-fu">
      <van-col span="8" class="lian-xi-ke-fu-item" @click="$refs.keFu.onSelect({type: '在线客服'})">
        <div class="ke-fu-icon">
          <i class="ke-fu-icon-icon iconfont icon-kefu"></i>
        </div>
        <span class="lian-xi-ke-fu-text">在线客服</span>
      </van-col>
      <van-col span="8" class="lian-xi-ke-fu-item liu-yan" @click="$refs.keFu.onSelect({type: '客服电话'})">
        <div class="ke-fu-icon">
          <i class="ke-fu-icon-icon iconfont icon-dianhua"></i>
        </div>
        <span class="lian-xi-ke-fu-text">客服电话</span>

      </van-col>
      <van-col span="8" class="lian-xi-ke-fu-item" @click="$refs.faBu.show()">
        <div class="ke-fu-icon">
          <i class="ke-fu-icon-icon iconfont icon-kefuxiaoxi"></i>
        </div>
        <span class="lian-xi-ke-fu-text">发布需求</span>
      </van-col>
    </van-row>
    <div class="lun-bo-qie-huan">
      <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          height="22"
      >
        <van-swipe-item v-for="(vo,index) in list" :key="index">
          <div class="nei-rong">
            {{ vo.user_nickname }}发布需求&nbsp;&nbsp;&nbsp;{{ vo.title }}&nbsp;&nbsp;&nbsp;{{ vo.money }}
          </div>
        </van-swipe-item>

      </van-swipe>
    </div>
    <div style="clear: both"></div>
    <lian-xi-ke-fu ref="keFu"/>
  </div>
  <fa-bu-xu-qiu ref="faBu"/>
</template>

<script>

import http from "../../../api/http";
import {hidden, unitConvert} from "@/api/GongJu";
import FaBuXuQiu from "../../open/FaBuXuQiu";
import LianXiKeFu from "../../open/LianXiKeFu";
import _ from 'lodash'

export default {
  name: 'ZuiXingFaBu',
  components: {LianXiKeFu, FaBuXuQiu},
  mixins: [],
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      http.get('/gzhphp/guang_gao/getDongTai').then(da => {
        this.list = da.data.map(n => {
          n.user_nickname = hidden(n.user_nickname, 1, 0)
          if (_.toNumber(n.money) <= 0) {
            n.money = '可议价'
          } else {
            n.money = unitConvert(n.money)
          }

          return n
        })
        /*da.data.forEach((n, i) => {
          if (i % 3 === 0) this.list.push(da.data.splice(i, i + 3))
        })*/
      })
    },

  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.nei-rong {
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
  line-height: 20px;
  height: 20px;
  font-size: 14px;
  color: #565656;
}

.dong-tai {
  margin: 10px;
  background-color: @white;
  border-radius: 10px;
  min-height: 100px;
  padding: 15px 0 25px;
  box-shadow: @yin-ying-all;

  .lun-bo-qie-huan {
    background-color: @gray-2;
    height: 78px;
    overflow-y: hidden;
    margin: 25px 10px 0;
    padding: 10px;
    border-radius: 10px;
  }

  .lian-xi-ke-fu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 10px;


    .lian-xi-ke-fu-item {
      text-align: center;

      &.liu-yan {
        //padding-left: 10px;
      }

      .lian-xi-ke-fu-text {
        //padding-left: 8px;
        //padding-top: 10px;
      }

      .ke-fu-icon {
        width: 43px;
        height: 43px;
        background: @blue;
        border-radius: 100%;

        text-align: center;
        line-height: 43px;
        margin: auto auto 6px auto;


        .ke-fu-icon-icon {
          font-size: 20px;
          color: @white;
        }
      }
    }


  }

}
</style>
