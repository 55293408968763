<template>
  <div class="fen-lei-content">
    <div class="fen-lei">
      <div class="fen-lei-list" @click="tiaoZHuan(vo)" v-for="vo in list[0]" :key="vo.id">
        <div class="fen-lei-image">
          <img :src="vo.image[0].url"/>
        </div>
        <div class="fen-lei-name">
          {{ vo.name }}
        </div>
      </div>
    </div>
    <div style="clear:both"></div>
    <div class="fen-lei" style="margin-top: 20px" v-if="list.length">
      <div class="fen-lei-list" @click="tiaoZHuan(vo)" v-for="vo in list[1]" :key="vo.id">
        <div class="fen-lei-image">
          <img :src="vo.image[0].url"/>
        </div>
        <div class="fen-lei-name">
          {{ vo.name }}
        </div>
      </div>
      <div class="fen-lei-list gen-duo" @click="$router.push({name:'QuanBuType'})">
        <div class="fen-lei-image">
          <i class="iconfont icon-menu-s"/>
        </div>
        <div class="fen-lei-name">
          全部分类
        </div>
      </div>
    </div>
    <div style="clear:both"></div>
  </div>
</template>

<script>
import http from "../../../api/http";
import XEUtils from "xe-utils";

export default {
  name: 'jieJianFenLei',
  mixins: [],
  data() {
    return {
      list: [],
      list2: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    tiaoZHuan(vo) {
      this.$router.push({name: 'FuWuDaTing', query: {type_1: vo.id, type_name_1: vo.name}})
    },
    getData() {
      http.get('/gzhphp/guang_gao/getHomeFuWuType').then(da => {
        if (da.code === 1) {
          this.list = XEUtils.chunk(XEUtils.slice(da.data, 0, 7), 4)
        }
      })
    }
  },
  watch: {},
  computed: {
    getList() {
      return XEUtils.slice(this.getList, 0, 9)
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.fen-lei-content {
  background-color: @white;
  border-radius: 10px;
  padding: 10px 0;
  margin: 0 10px;
  min-height: 155px;

  .fen-lei {
    text-align: center;
    margin-top: 5px;
    //display: flex;
    //flex-direction: row;
    //flex-wrap: nowrap;
    //align-content: center;
    //align-items: center;
    //justify-content: space-evenly;

    .gen-duo {
      color: @blue !important;

      .fen-lei-name {
        color: @blue !important;
      }
    }

    .fen-lei-list {
      float: left;
      width: 25%;
      overflow: hidden;
      padding: 0 0;
      //width: 70px;


      .fen-lei-image {
        line-height: 25px;
        height: 30px;
        width: 100%;

        i {
          font-size: 32px;
        }

        img {
          width: 28px;
          height: 28px;
        }
      }

      .fen-lei-name {
        font-size: 12px;
        margin-top: 8px;
        color: #565656;
        padding: 0 2px;
        white-space: nowrap; //不换行
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //变成...
      }
    }

  }
}

</style>
