<template>
  <div>
    <div class="da-hang">
      <van-tabs v-model="active" @click="change">
        <van-tab title="找服务" name="FuWuDaTing"></van-tab>
        <van-tab title="找项目" name="XiangMuDaTing"></van-tab>
        <van-tab title="案例" name="AnLiDaTing"></van-tab>
      </van-tabs>
    </div>
    <div class="zui">
      <template v-for="vo in list" :key="vo.g_id">
        <fu-wu-shang-list :to="{name:'FuWuShangInfo',query:{g_id:vo.g_id}}" :fu-wu-shang-info="vo"/>
      </template>
    </div>
  </div>
  <van-divider>
    <router-link :to="{name:'FuWuDaTing'}">查看更多服务商</router-link>
  </van-divider>
  <div style="height: 25px;"></div>
</template>

<script>
import FuWuShangList from "./FuWuShangList";

export default {
  name: 'HomeZhaoFuWu',
  components: {FuWuShangList},
}
</script>
<script setup>
import {ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import http from "../../../api/http";

let list = ref([])
let active = ref(0)
let router = useRouter()

function change(page) {
  router.push({name: page})
}



getData()

function getData() {
  http.get('/gzhphp/fu_wu_shang/getSuiJiFuWuShang').then(da => {
    if (da.code === 1) {
      list.value = da.data
    }
  })
}

function href(item) {
  router.push({name: 'FuWuShangInfo', query: {g_id: item.g_id}})
}
</script>

<style scoped lang="less">
::v-deep .van-tabs__nav{
  background-color: transparent;
  .van-tab__text{
    font-size: 16px;
  }
}
</style>
