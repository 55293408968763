<template>
  <div class="lun-bo">
    <van-swipe @change="onChange" :autoplay="3000">
      <van-swipe-item v-for="vo in list">
        <a :href="vo.url" target="_blank">
          <van-image class="zhi-cun" :src="vo.image[0].url"/>
        </a>
      </van-swipe-item>
    </van-swipe>
    <div class="tong-ji">
      <tong-ji/>
    </div>
  </div>
</template>

<script>
import http from "@/api/http";
import TongJi from "./tongJi";

export default {
  name: 'IndexLunBo',
  components: {TongJi},
  data() {
    return {
      list: []
    }
  },
  emits: ['change'],
  mounted() {
    this.getData()
  },
  methods: {
    onChange(index) {
      this.$emit('change', this.list[index])
    },
    getData() {
      http.get("/gzhphp/guang_gao/getLunBoItem", {params: {slide_id: 2}}).then(da => {
        if (da.code === 1) {
          this.list = da.data
          this.onChange(0)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.lun-bo {
  position: relative;
  min-height: 150px;

  .tong-ji {
    position: absolute;
    width: 100%;
    bottom: 6px;

  }
}
.zhi-cun{
  width: 100%;
  //max-height: 300px;
}
</style>
