<script>
import {ref, onMounted} from "vue";
import http from "@/api/http";
import {map} from "xe-utils";
import moment from "moment";

export default {
  name: 'HuiQiZhengCeHome',
  setup() {
    const list = ref([]);
    onMounted(() => {
      http.post('/gzhphp/policy/get_policy_list', {page: 1, pageSize: 60})
          .then(({code, data}) => {
            if (code === 1) {
              list.value = map(data.data,n=>{
                n.shen_bao_start_time = moment(n.shen_bao_start_time).format('YYYY/MM/DD')
                n.shen_bao_end_time = moment(n.shen_bao_end_time).format('YYYY/MM/DD')
                return n
              })
            }
          })
    })
    return {
      list
    }
  }
}
</script>

<template>
  <div class="dong-tai">
    <div class="tal-flex tal-gap-5">
      <div class="tal-flex-1">
        <router-link :to="{name:'PolicyIndex'}">
          <img class="tal-w-full" src="http://yunzuofile.sxsgky.com/policy_of_benefiting_enterprises.png">
        </router-link>
      </div>
      <div class="tal-flex-1">
        <router-link :to="{name:'approvalProcess'}">
          <img class="tal-w-full" src="http://yunzuofile.sxsgky.com/project_approval.png">
        </router-link>
      </div>
    </div>
    <div class="lun-bo-qie-huan">
      <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          height="22"
      >
        <van-swipe-item v-for="(vo,index) in list" :key="index">
          <div class="nei-rong van-ellipsis">
            申报时间：{{vo.shen_bao_start_time}} - {{vo.shen_bao_end_time}}&nbsp;&nbsp;&nbsp;{{vo.name}}
          </div>
        </van-swipe-item>

      </van-swipe>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.dong-tai {
  margin: 10px;
  background-color: @white;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: @yin-ying-all;

  .lun-bo-qie-huan {
    background-color: @gray-2;
    height: 78px;
    overflow-y: hidden;
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
  }
}
</style>
