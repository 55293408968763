<template>
  <home-header fixed  />
  <index-lun-bo @change="lunBo"/>
  <div class="bei-jing" :style="getBackground">
    <jie-jian-fen-lei/>
    <hui-qi-zheng-ce-home />
<!--    <zui-xing-fa-bu/>-->
    <home-he-zuo-huo-ban/>
    <home-zhao-fu-wu/>
  </div>
  <footer-nav fixed pathName="Index"/>
</template>

<script>
import homeHeader from "../open/homeHeader";

import footerNav from "@/views/open/footerNav.vue";
import IndexLunBo from "@/views/index/public/IndexLunBo.vue";
import JieJianFenLei from "./public/jieJianFenLei";
import ZuiXingFaBu from "./public/ZuiXingFaBu";
import HomeHeZuoHuoBan from "./public/HomeHeZuoHuoBan";
import HomeZhaoFuWu from "./public/HomeZhaoFuWu";
import HuiQiZhengCeHome from "@/views/index/public/HuiQiZhengCeHome.vue";

export default {
  name: 'Index',
  components: {
    HuiQiZhengCeHome,
    HomeZhaoFuWu, HomeHeZuoHuoBan, ZuiXingFaBu, JieJianFenLei, IndexLunBo, footerNav, homeHeader},
  data() {
    return {
      lunBoItem: {},
      getBackground: {}
    }
  },
  methods: {
    lunBo(item) {
      this.lunBoItem = item
      if (this.lunBoItem?.image2?.length) {
        this.getBackground = {
          'background-image': `url(${this.lunBoItem.image2[0].url})`
        }
      }

    }
  },
  computed: {}
}
</script>

<style scoped>
.bei-jing {
  min-height: 400px;
  padding-top: 10px;
  background-size: 100% auto;
  margin-top: -5px;
  background-repeat: no-repeat;
}
</style>
