<template>
  <div class="tong-ji">
    <div v-for="(vo,index) in getList" :key="index">
      <div>
        <img
            class="image-number"
            v-for="(img,index2) in vo.icon"
            :key="index2"
            :src="img"
        >
      </div>
      <div>{{ vo.name }}</div>
    </div>
<!--    [{{ getList }}]-->
  </div>
</template>

<script>
import http from "@/api/http";

export default {
  name: 'tongJi',
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getData()

  },
  methods: {
    getData() {
      http.get('/gzhphp/guang_gao/getTongJi').then(da => {
        this.list = da.data
      })
    },
    getIcon(number) {
      number = number.toString().split('').map(n => require(`@/assets/icon/number/shu_zi_${n}.png`))
      return number
    }
  },
  computed: {
    getList() {
      return _.forEach(this.list,n => {
        n.icon = this.getIcon(n.total)
        return n
      })

    }
  }

}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.tong-ji {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: @white;
  text-align: center;

  .image-number{
    width: 16px;
    padding: 5px 2px;
  }
}
</style>
